export const title = 'JB-Labs'

export const appUrl = 'https://jb-labs.com'

export const firebaseConfig = {

  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

export const gitHubRepos = 'https://api.github.com/users/jimmybengtsson/repos'

export const gitHubRepoReadme = 'https://raw.githubusercontent.com/jimmybengtsson/'